@font-face {
  font-family: 'GTFlexa-ExtendedLight';
  src: local('GT-Flexa-Extended-Light'), url(../assets/fonts/gt-flexa/GT-Flexa-Extended-Light.woff) format('woff');
}

@font-face {
  font-family: 'GTFlexa-ExtendedMedium';
  font-weight: 500;
  src: local('MyFont'), url(../assets/fonts/gt-flexa/GT-Flexa-Extended-Medium.woff) format('woff');
}

@font-face {
  font-family: 'GTFlexa-StandardLight';
  src: local('MyFont'), url(../assets/fonts/gt-flexa/GT-Flexa-Standard-Light.woff) format('woff');
}

@font-face {
  font-family: 'GTFlexa-StandardMedium';
  src: local('MyFont'), url(../assets/fonts/gt-flexa/GT-Flexa-Standard-Medium.woff) format('woff');
}

@font-face {
  font-family: 'ConduitITCStd';
  src: url('../assets/fonts/schrift-conduit/ConduitITCStd-Bold.woff2') format('woff2'),
      url('../assets/fonts/schrift-conduit/ConduitITCStd-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ConduitITCStd';
  src: url('../assets/fonts/schrift-conduit/ConduitITCStd-Medium.woff2') format('woff2'),
      url('../assets/fonts/schrift-conduit/ConduitITCStd-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

