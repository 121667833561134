@import '~slick-carousel/slick/slick-theme.css';
@import '~slick-carousel/slick/slick.css';
@import "flickity";
@import './fonts';

::selection {
  background-color: #ff3d3d;
  color: #000;
}

.FullScreenModal {
  &__overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.9) !important;
    display: flex;
    align-items: center;
    .ReactModal__Content {
      width: 100%;
    }

    * {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

.CookiesModal__overlay {
  position: fixed;
  z-index: 10002;

  .ReactModal__Overlay {
    background-color: rgb(0,0,0,0.75) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    @media only screen and (max-width: 768px) {
     svg {
       width: 20px;
       top: 20px ;
       right: 20px;
     }
    }
  }

  .ReactModal__Content {
    background-color: #ffffff;
    margin: 32px auto;
    padding: 38px 110px 38px 56px;
    max-width: 840px;
    width: 100%;
    position: relative;

    @media only screen and (max-width: 768px) {
      padding: 20px 40px 20px 20px;
    }
  }
}

.flickity-button:disabled {
  opacity: 0.5 !important;
}

.flickity-enabled:focus .flickity-viewport {
  outline: none !important;
}


.top-space {
  top: 160px !important;
}

.d-none {
  display: none;
}

body > iframe {
  display: none !important;
}